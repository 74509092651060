import '../css/load.scss';

(function ($) {
	const $win = $(window);
	const $doc = $(document);
	const stripe = Stripe(appData.ajaxUrl.stripeData);

	initHeaderMenuToggler();
	initLanguageSwitcher();
	initTimeCounter();
	initOffersSelector();
	initSaleSubmit();
	initOffersAnalytics();
	// initStripeHolder();
	handleStripePayment();

	function addOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').addClass('no-scroll');
		$overlay.addClass('active');
	}

	function removeOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').removeClass('no-scroll');
		$overlay.removeClass('active');
	}

	function scrollToSection() {
		$('a.btn-scroller').on('click', function (e) {
			let sectionId = $(this).attr('href');
			if (!sectionId.startsWith('#')) {
				return;
			}

			e.preventDefault();

			$('html, body').animate(
				{
					scrollTop: $(sectionId).offset().top - 40
				},
				1000
			);
		});
	}

	function initHeaderMenuToggler() {
		if (!$('.header__menu-toggle').length) {
			return;
		}

		const $header = $('.header__container');

		$('.header .header__menu-toggle').on('click', function (e) {
			$(this).closest('.header__container').toggleClass('menu-open');
			addOverlay('.app-loader-overlay');
			e.preventDefault();
		});

		$('.header__menu-close svg').on('click', function (e) {
			$('.header__container').toggleClass('menu-open');
			e.preventDefault();
			removeOverlay('.app-loader-overlay');
		});

		$doc.mouseup(function (e) {
			if ($header.hasClass('menu-open')) {
				if (!$header.is(e.target) && $header.has(e.target).length === 0) {
					$header.removeClass('menu-open');
					removeOverlay('.app-loader-overlay');
				}
			}
		});
	}

	function initLanguageSwitcher() {
		if (!$('#lang-switcher-btn').length) {
			return;
		}

		const $langSwitcher = $('#lang-switcher');

		$('#lang-switcher-btn').on('click', function (e) {
			e.preventDefault();

			$langSwitcher.toggleClass('active');
			$('.app-loader-overlay').css('z-index', '999997');
			addOverlay('.app-loader-overlay');
		});

		$doc.mouseup(function (e) {
			if ($langSwitcher.hasClass('active')) {
				if (!$langSwitcher.is(e.target) && $langSwitcher.has(e.target).length === 0) {
					$langSwitcher.removeClass('active');
					$('.app-loader-overlay').css('z-index', '101');
					removeOverlay('.app-loader-overlay');
				}
			}
		});
	}

	function scrollToSection() {
		$('a.btn-scroller').on('click', function (e) {
			let sectionId = $(this).attr('href');
			if (!sectionId.startsWith('#')) {
				return;
			}

			e.preventDefault();

			$('html, body').animate(
				{
					scrollTop: $(sectionId).offset().top - 40
				},
				1000
			);
		});
	}

	function initTimeCounter() {
		if (!document.getElementById('time-counter')) {
			return;
		}

		const elementContainer = document.getElementById('time-counter');
		const timeInSec = elementContainer.dataset.counter;
		const countDownDate = new Date().getTime() + timeInSec * 1000;

		var x = setInterval(function () {
			const now = new Date().getTime();
			const distance = countDownDate - now;

			const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			let seconds = Math.floor((distance % (1000 * 60)) / 1000);

			if (seconds < 10) {
				seconds = '0' + seconds;
			}

			if (hours > 0) {
				elementContainer.innerHTML = hours + ' : ' + minutes + ' : ' + seconds;
			} else {
				elementContainer.innerHTML = minutes + ' : ' + seconds;
			}

			if (distance <= 0) {
				clearInterval(x);
				document.getElementById('time-counter').innerHTML = '';
			}
		}, 1000);
	}

	function initOffersSelector() {
		if (!$('input[name="offer"]').length) {
			return;
		}

		$('input[name="offer"]').on('change', function () {
			$('input[name="input_1"]').val(this.value);
		});

		$doc.ready(function () {
			$('input[name="input_1"]').val($('input[name="offer"]:checked').val());
		});
	}

	function initSaleSubmit() {
		$('.section--purchase-sale form .gfield_radio label').on('mouseup', function () {
			let selection = $(this).attr('for');
			let $selectionInput = $('#' + selection);
			$selectionInput.prop('checked', true);
			$(this).closest('form').submit();
		});
	}

	function initOffersAnalytics() {
		if (!$('input[name="input_52"]').length) {
			return;
		}

		$doc.ready(function () {
			$('input[name="input_52"]').val(window.location.search.substr(1));
		});
	}

	// Generate random data for testing
	function getRandomAddress() {
		const addresses = ['1234 Elm St', '5678 Maple Ave', '4321 Oak Blvd', '9876 Pine Dr'];
		return addresses[Math.floor(Math.random() * addresses.length)];
	}

	function getRandomCity() {
		const cities = ['New York', 'Los Angeles', 'Chicago', 'Houston', 'Phoenix'];
		return cities[Math.floor(Math.random() * cities.length)];
	}

	function getRandomPostalCode() {
		const postalCodes = ['10001', '90001', '60601', '77001', '85001'];
		return postalCodes[Math.floor(Math.random() * postalCodes.length)];
	}

	function getRandomEmail() {
		const domains = ['example.com', 'test.com', 'demo.com', 'sample.com'];
		const randomString = Math.random().toString(36).substring(2, 8);
		return `${randomString}@${domains[Math.floor(Math.random() * domains.length)]}`;
	}

	function initStripeHolder() {
		if (!document.getElementById('input_1_17')) {
			return;
		}

		// Prefill fields with random data
		const randomEmail = getRandomEmail();
		document.getElementById('input_1_17').value = getRandomAddress();
		document.getElementById('input_1_19').value = getRandomCity();
		document.getElementById('input_1_18').value = getRandomPostalCode();
		document.getElementById('input_1_13').value = randomEmail;
		document.getElementById('input_1_13_2').value = randomEmail;
		document.getElementById('input_1_11').value = 'test';
		document.getElementById('input_1_12').value = 'test';
		document.getElementById('input_1_15').value = '12345678';
		document.getElementById('input_1_14').value = '08/10/2024';
	}

	function handleStripePayment() {
		if (document.getElementById('payment-element') === null) {
			return;
		}

		const appearance = {
			theme: 'stripe'
		};
		const options = {
			mode: 'setup',
			amount: 0,
			currency: 'eur',
			setupFutureUsage: 'off_session',
			paymentMethodTypes: ['card', 'sepa_debit'],
			paymentMethodCreation: 'manual',

			appearance: appearance,
			layout: 'tabs',
			defaultCollapsed: false,
			locale: 'fr'
		};
		const elements = stripe.elements(options);
		const paymentElement = elements.create('payment');
		paymentElement.mount('#payment-element');

		const form = document.getElementById('gform_1');

		form.addEventListener('submit', async (event) => {
			event.preventDefault();

			const firstName = document.getElementById('input_1_11').value;
			const lastName = document.getElementById('input_1_12').value;
			const email = document.getElementById('input_1_13').value;
			const line1 = document.getElementById('input_1_17').value;
			const city = document.getElementById('input_1_19').value;
			const postalCode = document.getElementById('input_1_18').value;
			const country = document.getElementById('input_1_20').value;
			const amount = 100;

			const { error: submitError } = await elements.submit();
			if (submitError) {
				console.error('Error submitting elements:', submitError.message);
				document.getElementById('payment-result').textContent = submitError.message;
				return;
			}

			const stripeClientSecret = await createSetupIntent(
				firstName,
				lastName,
				email,
				line1,
				city,
				postalCode,
				country,
				0
			);

			const { setupIntent, error } = await stripe.confirmSetup({
				elements,
				clientSecret: stripeClientSecret,
				confirmParams: {
					return_url: window.location.origin + '/thank-you-page'
				},
				redirect: 'if_required'
			});

			if (error) {
				console.log(error);
				document.getElementById('payment-result').textContent = error.message;
			} else {
				document.getElementById('payment-result').textContent = 'Payment successful!';
				const stData = await fetchSetupDetails(setupIntent.id);

				if ($('input[name="input_7"]').length) {
					$('input[name="input_7"]').val(stData);
				}

				form.submit();
			}
		});
	}

	async function createSetupIntent(
		firstName,
		lastName,
		email,
		line1,
		city,
		postalCode,
		country,
		amount
	) {
		const response = await fetch(
			appData.ajaxUrl[0] + '?action=app_stripe_create_setup_intent',
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					amount: amount,
					shipping: {
						name: `${firstName} ${lastName}`,
						address: {
							line1: line1,
							city: city,
							postal_code: postalCode,
							country: country
						}
					},
					email: email
				})
			}
		);
		const data = await response.json();

		return data.data.clientSecret;
	}

	async function fetchSetupDetails(setupIntentId) {
		const response = await fetch(
			appData.ajaxUrl[0] +
				'?action=app_stripe_fetch_setup_details&ihaSetupIntentId=' +
				setupIntentId
		);
		const setupDetailsRes = await response.json();

		const setupDetails = setupDetailsRes.data.setupIntent;

		// Map the setup intent data to your custom structure
		const structuredData = {
			orderData: {
				order_type: 'initial', // Hardcoded value
				store: 'journal_de_la_source_fr', // Hardcoded value
				payment: {
					method: 'stripe_payments', // Hardcoded value
					additional_information: {
						customer_stripe_id: setupDetails.customer,
						tr_id: setupDetails.id,
						token: setupDetails.payment_method,
						is_captured: false
					}
				}
			}
		};

		// Display the structured order data
		const resultData = JSON.stringify(structuredData, null, 2);
		// document.getElementById('payment-details').textContent = resultData;

		return resultData;
	}
})(jQuery);
